import LayoutComponent from "../components/layout/LayoutComponent";
import {Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay} from 'swiper/modules';

import '../styles/Home.css';
import '../styles/About.css';
import '../styles/Album.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import React, {useEffect, useState} from "react";
import gsap from "gsap";
import AboutComponent from "../components/About";
import AlbumComponent from "../components/Abum";
import Footer from "../components/Footer";
import {useHistory} from "react-router-dom";
import {linkLogo, linkLogoWhite} from "../components/layout/Header";
export const LogoMain1 = "assets/img/main/z4307300251583_3af3f1e2fd8768684b5c8cd821435240.jpg"
export const LogoMain2 = "assets/img/main/z4307299535479_df2a4c33481876080eeae435abd20660(2).jpg"
export const LogoMain3 = "assets/img/main/sushi-g86014edd4_1280.jpg"

function Home(props) {
    const [currentNumber, setCurrentNumber] = useState(1);
    const history = useHistory();

    let effectStyle = {
        opacity: 1,
        x: 0,
        y: 0,
        ease: "power3.inOut",
        delay: 0.4,
        stagger: 0.2
    }
    useEffect(() => {
        gsap.to(".swiper > .swiper-button-next-custom", 2, {...effectStyle, x: -10})
        gsap.to(".swiper > .swiper-button-prev-custom", 2, effectStyle)
        gsap.to(".swiper > .swiper-scrollbar-custom", 2, effectStyle)
    }, [])
    let headerConent = [
        {
            strong: "Gemütliche Atmosphäre",
            title: "Geeignet für Gruppe von Familien und Freunden."
        }, {
            strong: "Originales Rezept",
            title: "Das Essen mit originalem Rezept von unserem Chefkoch."
        }, {
            strong: "Gesundes Essen Jeden tag",
            title: "„Immer frisch und gesund, sowie mit Liebe zubereitet“ ist unser Slogan."
        },
    ];

    let urls = [
        LogoMain1,
        LogoMain2,
        LogoMain3
    ];
    const handleClick = () => {
        history.push("/booking");
    }
    const handleOpenLink = () => {
        window.open("https://restaurant-kando-restaurant-freiberg.order.app.hd.digital/menus", "_blank");
    }
    return (
        <LayoutComponent>
            {/*, Autoplay*/}
            <div className="slider-container">

                <div className="sliders">
                    <Swiper
                        spaceBetween={50}
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
                        slidesPerView={1}

                        effect={"creative"}
                        loop={true}
                        speed={1500}
                        autoplay={{
                            delay: 5000,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next-custom',
                            prevEl: '.swiper-button-prev-custom'
                        }}
                        pagination={{
                            el: ".swiper-pagination",
                            clickable: true,
                            renderBullet: function (index, className) {
                                return '<span class="' + className + ' tab-' + (index + 1) + '">' + "</span>";
                            }
                        }}
                        scrollbar={{
                            el: '.swiper-scrollbar-custom',
                            draggable: true,
                            dragSize: 170
                        }}
                        onSwiper={(swiper) => {
                        }}
                        onSlideChange={(index) => {
                            let hidden = {
                                ...effectStyle,
                                x: 40,
                                opacity: 0,
                                stagger: 0,
                                delay: 0
                            }

                            setCurrentNumber(index.realIndex + 1)
                            gsap.to(".swiper-slide-next > .wrap-slider > .infor-slide > *", 0, hidden)
                            gsap.to(".swiper-slide-prev > .wrap-slider > .infor-slide > *", 0, hidden)
                            gsap.to(".swiper-slide-prev > .wrap-slider > .infor-slide > .wrap-btn > *", 0, hidden)
                            gsap.to(".swiper-slide-next > .wrap-slider > .infor-slide  > .wrap-btn > *", 0, hidden)
                            gsap.to(".swiper-slide-prev > .wrap-slider > .infor-slide > .header-h1 > .flex-string > *", 0, hidden)
                            gsap.to(".swiper-slide-next > .wrap-slider > .infor-slide > .header-h1 > .flex-string > *", 0, hidden)


                            gsap.to(".swiper-slide-active > .wrap-slider > .infor-slide > .sub-title", 0.5, effectStyle)
                            gsap.to(".swiper-slide-active > .wrap-slider > .infor-slide > .wrap-btn", 0.5, effectStyle)
                            gsap.to(".swiper-slide-active > .wrap-slider > .infor-slide > .wrap-btn > .hover-filled-slide-right", 1, {
                                ...effectStyle,
                                ease: "back",
                                delay: 1,
                            })

                            gsap.to(".swiper-slide-active > .wrap-slider > .infor-slide > .header-h1", 0.5, effectStyle)
                            gsap.to(".swiper-slide-active > .wrap-slider > .infor-slide > .header-h1 > .flex-string > .animationOne", 0.3, {
                                ...effectStyle,
                                ease: "back",
                                stagger: 0.1,
                                delay:0.3,
                            })
                            gsap.to(".active-number", 0, {...effectStyle, y: -20, opacity: 0, delay: 0})
                            gsap.to(".active-number", 0.5, effectStyle)
                            gsap.to(".span-number-page", 0, {...effectStyle, y: -20, opacity: 0, delay: 0})
                            gsap.to(".span-number-page", 1.1, effectStyle)


                        }}
                    >

                        {
                            headerConent.map((item, index) => {
                                return (
                                    <SwiperSlide>
                                        <div className="wrap-slider">
                                            <div className="logo">
                                                <img src={linkLogoWhite} width={"50px"} height={"50px"}
                                                     alt=""/>
                                            </div>
                                            <div className="thumbnail">
                                                <div className="over-play"></div>
                                                <img src={urls?.[index]} alt=""/>
                                            </div>
                                            <div className="infor-slide">
                                                <div className={"header-h1"}>
                                                   <strong className={"flex-string"}>
                                                        <TextSplitDiv title={item.strong}/>
                                                    </strong>

                                                </div>
                                                <div className="sub-title">
                                                    {item.title}
                                                </div>
                                                <div className={"wrap-btn"}>
                                                    <button className="hover-filled-slide-right" onClick={handleClick}>
                                                        <span> Tisch reservieren</span>
                                                    </button>
                                                    <button className="hover-filled-slide-right" onClick={handleOpenLink}>
                                                        <span> Online bestellen</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                        <div className="swiper-pagination"></div>


                        <div className="swiper-scrollbar-custom"></div>


                        <div className="swiper-button-prev-custom">
                            PREV
                        </div>
                        <div className="swiper-button-next-custom">
                            NEXT
                        </div>
                        <div className="swiper-pagination swiper-pagination-nested"></div>
                    </Swiper>
                </div>
                <div className="count">
                    <div className="active-number">
                        {`0${currentNumber}`}
                    </div>
                    <span>
                        / 03
                    </span>
                </div>

            </div>
            <p className="or-divider italic">ÜBER UNS</p>
            <AboutComponent handleClick={handleClick} handleOpenLink={handleOpenLink}/>
            <p className="or-divider italic">GALERIE</p>
            <AlbumComponent handleClick={handleClick} handleOpenLink={handleOpenLink}/>
            <div className="center-bg">
                <img src={linkLogo} alt=""/>
            </div>
            {/*<AboutComponent number={2}/>*/}

            <Footer/>
        </LayoutComponent>
    )
}

const TextSplitDiv = (props) => {
    let title = props?.title;
    let result = [];
    for (let i = 0; i < title.length; i++) {
        result.push(<span className={"animationOne"}>{title.charAt(i)}</span>)
    }
    return result
}
export default Home;