const Tabd5 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Erfassung von allgemeinen Daten und Informationen</strong></h2>
                Die Internetseite der Kando GmbH erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person
                oder ein
                automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und
                Informationen
                werden in den Logfiles des Servers gespeichert. Erfasst werden können die (1) verwendeten Browsertypen
                und
                Versionen, (2) das vom zugreifenden System verwendete Betriebssystem, (3) die Internetseite, von welcher
                ein
                zugreifendes System auf unsere Internetseite gelangt (sogenannte Referrer), (4) die Unterwebseiten,
                welche über ein
                zugreifendes System auf unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit eines
                Zugriffs auf
                die Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der Internet-Service-Provider
                des
                zugreifenden Systems und (8) sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle
                von
                Angriffen auf unsere informationstechnologischen Systeme dienen.
            </div>
            <div className="card text-start p-3 mt-4">
                Bei der Nutzung dieser allgemeinen Daten und Informationen zieht der Klubhaus e.V. keine Rückschlüsse
                auf die
                betroffene Person. Diese Informationen werden vielmehr benötigt, um (1) die Inhalte unserer
                Internetseite korrekt
                auszuliefern, (2) die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren, (3) die
                dauerhafte
                Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik unserer Internetseite zu
                gewährleisten sowie (4) um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
                Strafverfolgung
                notwendigen Informationen bereitzustellen. Diese anonym erhobenen Daten und Informationen werden durch
                den Klubhaus
                e.V. daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den Datenschutz und die
                Datensicherheit in
                unserem Verein zu erhöhen, um letztlich ein optimales Schutzniveau für die von uns verarbeiteten
                personenbezogenen
                Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von allen durch eine
                betroffene Person
                angegebenen personenbezogenen Daten gespeichert.
            </div>
        </>
    )

}
export default Tabd5;