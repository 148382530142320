const Tabd6 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Routinemäßige Löschung und Sperrung von personenbezogenen Daten</strong></h2>
                Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der
                betroffenen Person nur
                für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch den
                Europäischen
                Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder Vorschriften, welchen
                der für die
                Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.
                Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder
                einem anderen
                zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten
                routinemäßig und
                entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.

            </div>


        </>
    )

}
export default Tabd6;