const Tabd10 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen oder einem Dritten
                    verfolgt
                    werden</strong></h2>
                Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DS-GVO ist unser berechtigtes
                Interesse die
                Durchführung unserer Geschäftstätigkeit zugunsten des Wohlergehens all unserer Mitarbeiter und unserer
                Anteilseigner
            </div>

        </>
    )

}
export default Tabd10;