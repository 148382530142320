import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"
import React, {useEffect} from "react";
export const imageContact1 = "assets/img/shutterstock_2002848872-Converted-01-scaled.jpg"
export const imageContact2 = "assets/img/contact-bg.jpg"
export const imageContact3 = "assets/img/shutterstock_2002848872-Converted-01-scaled.jpg"
const AboutComponent = (props) => {
    let {number = 1,handleClick,handleOpenLink} = props;
    // let animationString = "restart reverse restart restart"
    let animationString = "play none none none"
    let urls = [
        imageContact1,
        imageContact2,
        imageContact3,
    ]
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        let scrollTrigger = {
            end: 'bottom center',
            start: 'top bottom',
            // markers:true,
            toggleActions: animationString
        }
        gsap.to(".first-card .left-card", 2.5, {
            scrollTrigger: {...scrollTrigger, trigger: ".first-card"},
            x: 0,
            opacity: 1,
        })
        gsap.fromTo(".first-card .left-card .content > *", {
            opacity: 0,
        }, {
            scrollTrigger: {...scrollTrigger, trigger: ".first-card"},
            opacity: 1,
            duration: 3,
            ease: "power3.inOut"
        })
        gsap.to(".first-card .right-card", 3.5, {
            scrollTrigger: {...scrollTrigger, trigger: ".first-card"},
            x: 0,
            opacity: 1,
        })

        gsap.to(".second-card .left-card", 2.5, {
            scrollTrigger: {...scrollTrigger, trigger: ".second-card"},
            x: 0,
            opacity: 1,
        })
        gsap.fromTo(".second-card .left-card .content > * ", {
            opacity: 0,
        }, {
            scrollTrigger: {...scrollTrigger, trigger: ".second-card"},
            opacity: 1,
            duration: 3.5,
            ease: "power3.inOut"

        })
        gsap.to(".second-card .right-card", 3.5, {
            scrollTrigger: {...scrollTrigger, trigger: ".second-card"},
            x: 0,
            opacity: 1,
        })
        gsap.to(".third-card .left-card", 2.5, {
            scrollTrigger: {...scrollTrigger, trigger: ".third-card "},
            x: 0,
            opacity: 1,
        })
        gsap.fromTo(".third-card .left-card .content > * ", {
            opacity: 0,
        }, {
            scrollTrigger: {...scrollTrigger, trigger: ".third-card"},
            opacity: 1,
            duration: 3,
            ease: "power3.inOut"
        })
        gsap.to(".third-card .right-card", 3.5, {
            scrollTrigger: {...scrollTrigger, trigger: ".third-card"},
            x: 0,
            opacity: 1,
        })

    }, [])
    switch (number) {
        case 2:
            return <div className="container-about">

                <div className="wrap-about ">
                    <div className="about0card third-card">
                        <div className="left-card">
                            <div className="content">
                                <p>
                                    Ob ein romantischer Abend zu zweit, eine ausgefallene Geburtstagsfeier oder ein
                                    seriöses
                                    Firmentreffen: Gao Restaurant macht jedes Erlebnis einzigartig.
                                </p>
                                <h2>
                                    WHEN FOOD IS MORE THAN JUST FOOD
                                </h2>
                                <p>
                                    Nimm dir eine Auszeit und erlebe eine einzigartige Komposition aus Aromen, Farben
                                    und
                                    Gerüchen auf die panasiatische Art. Wir freuen uns auf Dich!
                                </p>
                                <p>
                                    rofessioneller Service, eine edle Atmosphäre und unglaubliches Essen begeistern
                                    unsere
                                    Gäste.
                                </p>
                            </div>
                        </div>
                        <div className="right-card">
                            <div className="triangle triangle-1"></div>
                            <img src={urls?.[0]} alt=""/>
                        </div>
                    </div>
                </div>

            </div>
        default:
            return (
                <div className="container-about">

                    <div className="wrap-about ">
                        <div className="about0card first-card">
                            <div className="left-card">
                                <div className="content">
                                    <div className="title">
                                        <h2>
                                            köstlich
                                            &amp; gesund
                                        </h2>
                                    </div>
                                    <hr/>
                                    <div className="wrap-button">
                                        <div className="paragraph">
                                            Genießen Sie das Geheimrezept von unserem Chefkoch
                                        </div>

                                        <div className={"wrap-btn-custom"}>
                                            <button className="hover-filled-slide-right" onClick={handleClick}>
                                                <span> Tisch reservieren</span>
                                            </button>
                                            <button className="hover-filled-slide-right" onClick={handleOpenLink}>
                                                <span> Online bestellen</span>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="right-card">
                                <div className="triangle triangle-1"></div>
                                <img src={urls?.[1]} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-about ">
                        <div className="about0card second-card">
                            <div className="right-card">
                                <div className="triangle triangle-2"></div>
                                <img src={urls?.[2]} alt=""/>
                            </div>
                            <div className="left-card">
                                <div className="content">
                                    <div className="title">
                                        <h2>
                                            Dein LieblingsSushi - Deine vietnamesische Küche
                                        </h2>
                                    </div>
                                    <hr/>
                                    <div className="paragraph">
                                        <p>
                                            Unser Restaurant befindet sich in <strong>
                                            <p className="stuff link" onClick={() => {
                                                window.open("https://maps.app.goo.gl/EwsJpzy3Zy9o8Fmb8", "_blank");
                                            }}>Am Bahnhof 3, 09599 Freiberg im Alekto Hotel</p>
                                        </strong>. Bei uns wird Ihnen leckeres Sushi,
                                            vietnamesische Spezialitäten servieren. Genießen Sie unsere Spezialitäten
                                            aus besten
                                            Zutaten.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
    }

}
export default AboutComponent;