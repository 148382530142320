const Tabd9 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Rechtsgrundlage der Verarbeitung</strong></h2>
                Art. 6 I lit. a DS-GVO dient unserem Verein als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir
                eine
                Einwilligung für einen bestimmten Verarbeitungszweck einholen.
            </div>
            <div className="card text-start p-3 mt-1">
                Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei die
                betroffene
                Person ist, erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine
                Lieferung
                von Waren oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die
                Verarbeitung
                auf Art. 6 I lit. b DS-GVO.
            </div>
            <div className="card text-start p-3 mt-1">
                Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen
                erforderlich sind,
                etwa in Fällen von Anfragen zur unseren Produkten oder Leistungen. Unterliegt unser Verein einer
                rechtlichen
                Verpflichtung durch welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie
                beispielsweise zur
                Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO.
            </div>
            <div className="card text-start p-3 mt-1">
                In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um
                lebenswichtige
                Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre
                beispielsweise der
                Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein Alter,
                seine
                Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder
                sonstige Dritte
                weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen. Letztlich
                könnten
                Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO beruhen.
            </div>
            <div className="card text-start p-3 mt-1">
                Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten
                Rechtsgrundlagen erfasst
                werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Vereins oder eines
                Dritten
                erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht
                überwiegen. Solche
                Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen
                Gesetzgeber besonders
                erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein
                könnte, wenn die
                betroffene Person ein Kunde des Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DS-GVO).
            </div>

        </>
    )

}
export default Tabd9;