const Tabd11 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Dauer, für die die personenbezogenen Daten gespeichert werden</strong></h2>
                Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche
                Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern
                sie nicht
                mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind
            </div>


        </>
    )

}
export default Tabd11;