const Tabd2 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Begriffsbestimmungen</strong></h2>
                Die Datenschutzerklärung der Kando GmbH. beruht auf den Begrifflichkeiten, die durch den Europäischen
                Richtlinien-
                und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere
                Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner
                einfach
                lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten
                Begrifflichkeiten
                erläutern.
                Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>a) personenbezogene Daten</strong></h2>
                Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
                natürliche
                Person (im Folgenden „betroffene Person“) beziehen. Als identifizierbar wird eine natürliche Person
                angesehen, die
                direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
                Kennnummer, zu
                Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck
                der
                physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
                Identität dieser
                natürlichen Person sind, identifiziert werden kann.
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>b) betroffene Person</strong></h2>
                Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
                personenbezogene Daten von
                dem für die Verarbeitung Verantwortlichen verarbeitet werden.
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>c) Verarbeitung</strong></h2>
                Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
                solche
                Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die
                Organisation, das
                Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die
                Offenlegung
                durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die
                Verknüpfung, die
                Einschränkung, das Löschen oder die Vernichtung.
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>d) Einschränkung der Verarbeitung</strong></h2>
                Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel,
                ihre künftige
                Verarbeitung einzuschränken.
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>e) Profiling</strong></h2>
                Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass
                diese
                personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
                Person
                beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage,
                Gesundheit,
                persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser
                natürlichen
                Person zu analysieren oder vorherzusagen.
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>f) Pseudonymisierung</strong></h2>
                Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die
                personenbezogenen
                Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person
                zugeordnet
                werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und
                organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer
                identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>g) Verantwortlicher oder für die Verarbeitung Verantwortlicher</strong></h2>
                Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische Person,
                Behörde,
                Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
                Verarbeitung
                von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das
                Unionsrecht oder
                das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die
                bestimmten
                Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>h) Auftragsverarbeiter</strong></h2>
                Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
                Stelle, die
                personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
            </div>

            <div className="card text-start mt-4 p-3">
                <h2><strong>i) Empfänger</strong></h2>
                Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der
                personenbezogene
                Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht.
                Behörden, die im
                Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
                möglicherweise
                personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>j) Dritter</strong></h2>
                Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der
                betroffenen
                Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren
                Verantwortung
                des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
                verarbeiten.
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>k) Einwilligung</strong></h2>
                Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter
                Weise und
                unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen
                bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung
                der sie
                betreffenden personenbezogenen Daten einverstanden ist.
            </div>


        </>
    )

}
export default Tabd2;