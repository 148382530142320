import './App.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';


import CommingSoon from "./components/CommingSoon";
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,

} from "react-router-dom";
import {Helmet} from "react-helmet";
import DefaultRoute from "./utils/DefaultRoute";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impressum";
import Booking from "./pages/Booking";

function App() {
    const publicPage = [
        {
            url: "/",
            component: Home,
            props: {}
        },
        {
            url: "/home",
            component: Home,
            props: {}
        },
        {
            url: "/booking",
            component: Booking,
            props: {}
        },
        {
            url: "/menu",
            component: Menu,
            props: {}
        },{
            url: "/Datenschutz",
            component: Datenschutz,
            props: {}
        },{
            url: "/Impressum",
            component: Impressum,
            props: {}
        },
    ];
    return (

        <Router>
            <div className="App">

                <div className="container d-flex align-items-center flex-column">
                    <Switch>
                        {
                            publicPage.map((page, index) => {
                                return (
                                    <DefaultRoute path={page.url} key={index} exact={true}>
                                        {React.createElement(page.component, page.props)}
                                    </DefaultRoute>
                                )
                            })
                        }


                    </Switch>

                </div>

            </div>
        </Router>
    );
}

export default App;
