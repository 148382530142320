const Tabd13 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Bestehen einer automatisierten Entscheidungsfindung</strong></h2>
                Als verantwortungsbewusster Verein verzichten wir auf eine automatische Entscheidungsfindung oder ein
                Profiling.
                Diese Datenschutzerklärung wurde durch den Datenschutzerklärungs-Generator der DGD Deutsche Gesellschaft
                für
                Datenschutz GmbH, die als Datenschutzbeauftragter tätig ist, in Kooperation mit den Datenschutz Anwälten
                der Kanzlei
                WILDE BEUGER SOLMECKE | Rechtsanwälte erstellt.
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>Haftung für Inhalte</strong></h2>
                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit
                und Aktualität
                der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG
                für eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
                als
                Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                überwachen oder
                nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                Entfernung oder
                Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                diesbezügliche
                Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                Bekanntwerden
                von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>Haftung für Links</strong></h2>
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                Deshalb
                können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten
                ist stets
                der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                Zeitpunkt der
                Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht
                erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer
                Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend
                entfernen.
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>Haftung für Links</strong></h2>
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                Deshalb
                können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten
                ist stets
                der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                Zeitpunkt der
                Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht
                erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer
                Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend
                entfernen.
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>Urheberrecht</strong></h2>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                Urheberrecht.
                Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
                Urheberrechtes
                bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser
                Seite sind
                nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht
                vom
                Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche
                gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um
                einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
                entfernen.
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>Hinweis Bildrechte und Copyright</strong></h2>
                © Die hier veröffentlichten Veranstaltungsaufnahmen, Bilder und Grafiken sind nach UrhG § 2 I Ziff.5
                geschützte
                Werke, Urheber sind Kando GmbH. Diese oben genannten Bilder dürfen nicht ohne unser ausdrücklich
                schriftliches
                Einverständnis vervielfältigt, kopiert, bearbeitet oder in jeglicher anderer Form, welche über das
                Betrachten
                hinausgeht, verwendet werden. Eine Nutzung, ohne unser ausdrückliches, schriftliches Einverständnis, ist
                strafbar
                und wird von uns strafrechtlich verfolgt.
            </div>


        </>
    )

}
export default Tabd13;