import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";


export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}



const Footer = ({textColor = ""}) => {
let isMobile = false;
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const history = useHistory();

    const handleClick = () => {
        history.push("/booking");
    }
    const handleOpenLink = () => {
        window.open("https://restaurant-kando-restaurant-freiberg.order.app.hd.digital/menus", "_blank");
    }
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
if (windowDimensions?.width <= 500){
    isMobile = true
}


    return (
        <div>
            <div className={`content ${textColor}`}>
                <p className="goodbye-head">RESERVIERUNG / STANDORT</p>
                <p className="goodbye-sub">
                    Mittlerweile sind wir an folgenden Standorten in deiner Nähe für Dich da:
                    <span className={"link"} onClick={() => {
                        window.open("https://maps.app.goo.gl/EwsJpzy3Zy9o8Fmb8", "_blank");
                    }}>
                                • Am Bahnhof 3, 09599 Freiberg im Alekto Hotel •
                            </span>. Und das an sieben Tagen die Woche, von Montag bis
                    Sonntag.
                </p>
            </div>
            <footer className="footer">
                <div className="footer__container">
                    <div className="map" onClick={() => {
                        window.open("https://maps.app.goo.gl/deU5szbC6FrfTGQo8", "_blank");
                    }}>
                        {isMobile ? <img
                                src="https://lh5.googleusercontent.com/p/AF1QipPPK7_RngvvV4xdagAf0IhZk9kPZDtFezzcYspm=w408-h306-k-no"
                                alt=""/> :
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2515.7563174109255!2d13.340730800000001!3d50.9097249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a75ff0f7bb5199%3A0x9c5d4bd7a090821!2sKando%20Restaurant!5e0!3m2!1svi!2s!4v1718644846198!5m2!1svi!2s"
                                width="600" height="450" allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        }
                    </div>
                    <div className="address">
                        <ul>
                            <li><strong>ADRESSE</strong><span className={"link"} onClick={() => {
                                window.open("https://maps.app.goo.gl/EwsJpzy3Zy9o8Fmb8", "_blank");
                            }}>• Am Bahnhof 3, 09599 Freiberg im Alekto Hotel •</span></li>
                            <li><strong>TELEFON</strong> 03731794455</li>
                            <li className={"optime"}><strong>ÖFFNUNGSZEITEN</strong>
                                <div>
                                    <p>Mo. – So.: 11.00 - 15.00 | 17.00 - 22.00Uhr</p>
                                    <p>Küchenschluss: 21h30</p>
                                </div>
                            </li>
                            <li>
                                <strong className={"f-label"}>Buch</strong>
                                <div className="flex">
                                    <button className="btn-footer" onClick={handleOpenLink}>
                                        <span>Online bestellen</span>
                                    </button>
                                    <button className="btn-footer" onClick={handleClick}>
                                        <span> Tisch reservieren</span>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </footer>
        </div>

    )
}
export default Footer