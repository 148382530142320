const Tabd12 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der personenbezogenen Daten;
                    Erforderlichkeit für den Vertragsabschluss; Verpflichtung der betroffenen Person, die
                    personenbezogenen
                    Daten bereitzustellen; mögliche Folgen der Nichtbereitstellung</strong></h2>
                EWir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil gesetzlich
                vorgeschrieben ist
                (z.B. Steuervorschriften) oder sich auch aus vertraglichen Regelungen (z.B. Angaben zum Vertragspartner)
                ergeben
                kann. Mitunter kann es zu einem Vertragsschluss erforderlich sein, dass eine betroffene Person uns
                personenbezogene
                Daten zur Verfügung stellt, die in der Folge durch uns verarbeitet werden müssen. Die betroffene Person
                ist
                beispielsweise verpflichtet uns personenbezogene Daten bereitzustellen, wenn unser Verein mit ihr einen
                Vertrag
                abschließt. Eine Nichtbereitstellung der personenbezogenen Daten hätte zur Folge, dass der Vertrag mit
                dem
                Betroffenen nicht geschlossen werden könnte. Vor einer Bereitstellung personenbezogener Daten durch den
                Betroffenen
                muss sich der Betroffene an einen unserer Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen
                einzelfallbezogen darüber auf, ob die Bereitstellung der personenbezogenen Daten gesetzlich oder
                vertraglich
                vorgeschrieben oder für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die
                personenbezogenen
                Daten bereitzustellen, und welche Folgen die Nichtbereitstellung der personenbezogenen Daten hätte.
            </div>


        </>
    )

}
export default Tabd12;