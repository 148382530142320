import LayoutComponent from "../components/layout/LayoutComponent";
import '../styles/document.css';
import React from "react";
import {useHistory} from "react-router-dom";
import {linkLogo} from "../components/layout/Header";

function Impressum(props) {
    const history = useHistory();

    const handleClick = (url) => {
        history.push(url);
    }
    return (
        <LayoutComponent>
            <div className={"container-doc"}>

                    <div className="back-to-home"  onClick={
                        () => handleClick("/home")
                    }>
                        <img
                            src={linkLogo}
                            width={"50px"} height={"50px"}
                            alt=""/>
                    </div>

                <h1>
                    Impressum
                </h1>
                <h2>
                    Anbieter Kando GmbH
                </h2>
                <ul>
                    <li>
                        • Am Bahnhof 3, 09599 Freiberg im Alekto Hotel •
                    </li>
                </ul>
                <ul>
                    <li>
                        Kontakt:
                    </li>
                    <li> Tel.: +493731/ 794455</li>
                    <li>Mail: INFO.KANDO.FREIBERG@GMAIL.COM</li>
                    <li>Geschäftsführer: Nguyen</li>
                    <li>USt-IdNr:</li>
                    <li>USt-IdNr:</li>
                </ul>

            </div>
        </LayoutComponent>
    )
}

export default Impressum;