import LayoutComponent from "../components/layout/LayoutComponent";
import '../styles/document.css';
import {useEffect, useState} from "react";
import gsap from "gsap";
import Tabd1 from "./daten/tab1";
import Tabd2 from "./daten/tab2";
import Tabd3 from "./daten/tab3";
import Tabd4 from "./daten/tab4";
import Tabd5 from "./daten/tab5";
import Tabd6 from "./daten/tab6";
import Tabd7 from "./daten/tab7";
import Tabd8 from "./daten/tab8";
import Tabd9 from "./daten/tab9";
import Tabd10 from "./daten/tab10";
import Tabd11 from "./daten/tab11";
import Tabd12 from "./daten/tab12";
import Tabd13 from "./daten/tab13";

function Datenschutz(props) {
    const [activeTab, setActiveTab] = useState(1);
    const tabs = [
        "Haftungsausschluss",
        "1. Begriffsbestimmungen",
        "2. Name und Anschrift des für die Verarbeitung Verantwortlichen",
        "3. Cookies",
        "4. Erfassung von allgemeinen Daten und Informationen",
        "5. Routinemäßige Löschung und Sperrung von personenbezogenen Daten",
        "6. Rechte der betroffenen Person",
        "7. Datenschutz bei Bewerbungen und im Bewerbungsverfahren",
        "8. Rechtsgrundlage der Verarbeitung",
        "9. Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen oder einem Dritten verfolgt werden",
        "10. Dauer, für die die personenbezogenen Daten gespeichert werden",
        "11. Gesetzliche oder vertragliche Voraussetzungen für die Bereitstellung personenbezogener Daten",
        "12. Bestehen einer automatisierten Entscheidungsfindung",
    ];
    useEffect(() => {
        let effectStyle = {
            opacity: 1,
            x: 0,
            ease: "power3.inOut",
            stagger: 0.2
        }

        gsap.to(".article.show", 0.5, effectStyle)
    }, [activeTab]);
    return (
        <LayoutComponent>
            <div className={"container-doc"}>
                <h1>
                    Datenschutz
                </h1>
                <ul className="tabs-block">

                    {
                        tabs.map((item,index) => {
                           let number = index + 1;
                            return (
                                <li className={`${activeTab === number ? "active" : ""} tab-main`}
                                    onClick={() => setActiveTab(number)}>{item}</li>
                            )
                        })
                    }

                </ul>
                <div className="article-block">
                    <Tab1 number={activeTab}/>
                    <Tab2 number={activeTab}/>
                    <Tab3 number={activeTab}/>
                    <Tab4 number={activeTab}/>
                    <Tab5 number={activeTab}/>
                    <Tab6 number={activeTab}/>
                    <Tab7 number={activeTab}/>
                    <Tab8 number={activeTab}/>
                    <Tab9 number={activeTab}/>
                    <Tab10 number={activeTab}/>
                    <Tab11 number={activeTab}/>
                    <Tab12 number={activeTab}/>
                    <Tab13 number={activeTab}/>
                </div>
            </div>
        </LayoutComponent>
    )
}

const Tab1 = ({number}) => {
    const activeTab = 1;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd1/>
        </div>
    )
}
const Tab2 = ({number}) => {
    const activeTab = 2;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd2/>
        </div>
    )
}
const Tab3 = ({number}) => {
    const activeTab = 3;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd3/>
        </div>
    )
}
const Tab4 = ({number}) => {
    const activeTab = 4;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd4/>
        </div>
    )
}
const Tab5 = ({number}) => {
    const activeTab = 5;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd5/>
        </div>
    )
}
const Tab6 = ({number}) => {
    const activeTab = 6;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd6/>
        </div>
    )
}
const Tab7 = ({number}) => {
    const activeTab = 7;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd7/>
        </div>
    )
}
const Tab8 = ({number}) => {
    const activeTab = 8;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd8/>
        </div>
    )
}
const Tab9 = ({number}) => {
    const activeTab = 9;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd9/>
        </div>
    )
}
const Tab10 = ({number}) => {
    const activeTab = 10;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd10/>
        </div>
    )
}

const Tab11 = ({number}) => {
    const activeTab = 11;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd11/>
        </div>
    )
}
const Tab12 = ({number}) => {
    const activeTab = 12;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd12/>
        </div>
    )
}
const Tab13 = ({number}) => {
    const activeTab = 13;
    return (
        <div className={`article ${number === activeTab ? "show" : ""}`}>
            <Tabd13/>
        </div>
    )
}
export default Datenschutz;