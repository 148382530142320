import {withRouter} from "react-router-dom";
import '../../styles/Header.css';
import React, {useEffect, useState} from "react";
import {useRef} from 'react';
import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import {useHistory} from "react-router-dom";
export const linkLogoWhite = "assets/logo/logo kando2-trang.png"
export const linkLogo = "assets/logo/logo-kando.png"

function Header(props) {
    const {headerHidden} = props;
    const [collsep, setCollsep] = useState(false);
    const history = useHistory();
    const container = useRef();
    const menuOpen = useRef();
    const menuOpenIcon = useRef();
    const menuClose = useRef();
    useEffect(() => {
        let effectStyle = {
            opacity: 1,
            x: 0,
            ease: "power3.inOut",
            delay: 0.4,
            stagger: 0.2
        }
        let timeEffect = 1.4;
        gsap.to(".header-label", timeEffect, effectStyle)
        gsap.to(".header-val", timeEffect, effectStyle)
        gsap.to(".contact-bandle", timeEffect, effectStyle)
        gsap.to(".contact-bandle", timeEffect, effectStyle)
        gsap.to(".button-icon > *", timeEffect, effectStyle)
        // gsap.to(".logo", timeEffect, effectStyle)
        gsap.to(".logo-base", timeEffect, effectStyle)
    }, [])
    useGSAP(
        () => {
            let t1 = gsap.timeline();
            const openMenu = () =>  {
                t1.to('.menu', {opacity: 1,display:"flex",zIndex:115, duration: 1,width:"100%",transform:" translateX(0)",height:"100vh", top: 0,left:0});
                t1.to('.nav-link', {opacity: 1, duration: 1, stagger: 0.1, marginTop: 0});
                t1.timeScale(1);


            }
            menuOpen.current.addEventListener('click', openMenu)
            menuClose.current.addEventListener('click', () => {
                t1.to('.menu', {opacity: 0,zIndex:-1,display:"none",width:"30vh",height:"100vh",transform:" translateX(-50%)", duration: 1, top: "0",left:"50%"})
                t1.to('.nav-link', {opacity: 0, marginTop: "-20px"});
                t1.timeScale(1.5);


            })
            menuOpenIcon.current.addEventListener('click', openMenu)


        },
        {scope: container}
    ); // <-- scope is for selector text (optional)

    const handleClick = (url) => {
        history.push(url);
    }
    const handleOpenLink = (link) => {
        window.open(link, "_blank");
    }
    const menu = [
        {
            label: "StartSeite",
            url: "/home",
            number:"01",
        },
        {
            label: "Online bestellen",
            link: "https://restaurant-kando-restaurant-freiberg.order.app.hd.digital/menus",
            number:"02",
        },
        {
            label: "Tisch reservieren",
            url: "/booking",
            number:"03",
        },
        {
            label: "Speisekarte",
            url: "/menu",
            number:"04",
        },
        {
            label: "Impressum",
            url: "/Impressum",
            number:"05",
        }, {
            label: "Datenschutz",
            url: "/Datenschutz",
            number:"06",
        },
    ]
    return (
        <>
            <div className={`header-container ${headerHidden ? 'hidden-bg-header' : ''}`}>
                <div className="logo-base" onClick={
                    () => handleClick("/home")
                }>
                    <img src={linkLogoWhite} width={"50px"} height={"50px"}
                         alt=""/>
                </div>
                <div className="line-horizontal"></div>
                <div className="contact-header">
                    <div className="tel">
                        <div className="header-label">
                            TELEFON:
                        </div>
                        <div className="header-val">
                            03731794455
                        </div>
                    </div>
                    <span className={"line-vertical "}></span>
                    <div className="location">
                        <div className="header-label">
                            ADRESSE:
                        </div>
                        <div className="header-val link" onClick={() => {
                            window.open("https://maps.app.goo.gl/EwsJpzy3Zy9o8Fmb8", "_blank");
                        }}>
                            • Am Bahnhof 3, 09599 Freiberg im Alekto Hotel •
                        </div>
                    </div>
                </div>
                <div className="line-horizontal"></div>
                <div className="btn-menu" ref={menuOpen} onClick={() => {
                    setCollsep(!collsep);
                }}>
                    <div className="menu-icon">
                        <div className={`hamburger-icon ${collsep ? 'active' : ''}`}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>

                <div ref={container} className="menu">
                    <div className="background">Menu</div>
                    <div className="exist" ref={menuClose} onClick={() => {
                        setCollsep(!collsep);
                    }}>
                        <img src="assets/icon/close-icon.svg" alt=""/>
                    </div>
                    <div className="menu-content">
                        <div className="menu-container">
                            <ul className="nav-list">
                                {
                                    menu?.map((item) => {

                                        return (
                                            <li>
                                                <a onClick={
                                                    () => item?.link ? handleOpenLink(item?.link) : handleClick(item?.url)
                                                } className="nav-link">
                                                    {item?.label}
                                                </a>
                                            </li>
                                        )
                                    })
                                }


                            </ul>
                        </div>
                        <div className="menu-container">
                            <div className="wrap-info-menu">
                                <div className="information">
                                    <h3 className="title">ADRESSE</h3>
                                    <p className="stuff link" onClick={() => {
                                        window.open("https://maps.app.goo.gl/EwsJpzy3Zy9o8Fmb8", "_blank");
                                    }}>• Am Bahnhof 3, 09599 Freiberg im Alekto Hotel •</p>
                                </div>
                                <div className="information">
                                    <h3 className="title">TELEFON</h3>
                                    <p className="stuff">03731794455</p>
                                </div>
                                <div className="information">
                                    <h3 className="title">ÖFFNUNGSZEITEN</h3>
                                    <p className="stuff">
                                        <div>
                                            <p>Mo. – So.:11.00 - 15.00 | 17.00 - 22.00Uhr</p>
                                            <p>Küchenschluss : 21:30 Uhr</p>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="contact-container">
                {/*<div className="line-vertical-rl"></div>*/}
                {/*<div className="contact-bandle">*/}
                {/*    Contact*/}
                {/*</div>*/}
                {/*<div className="line-vertical-rl"></div>*/}
                <div className="button-icon">
                    <div className="item-icon" onClick={() => {
                        window.open("https://wa.me/message/JHBWQUC6Z7FII1", "_blank");
                    }}>
                        <img src="assets/logo/whatapp.png" alt=""/>
                    </div>
                    <div className="item-icon" ref={menuOpenIcon} onClick={() => {
                        setCollsep(!collsep);
                    }}>
                        <img src="assets/logo/menu.jpg" alt=""/>
                    </div>
                    <div className="item-icon" onClick={() => {
                        window.open("https://www.facebook.com/profile.php?id=100094757642109", "_blank");
                    }}>
                        <img src="assets/logo/fb.jpg" alt=""/>
                    </div>
                    <div className="item-icon" onClick={() => {
                        window.open("https://www.instagram.com/kando_freiberg/", "_blank");
                    }}>
                        <img src="assets/logo/instagram.jfif" alt=""/>
                    </div>


                </div>

            </div>
        </>
    );
}

export default withRouter(Header);