const Tabd3 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Name und Anschrift des für die Verarbeitung Verantwortlichen</strong></h2>
                Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der
                Europäischen Union
                geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutz-rechtlichem Charakter ist der:
                <ul className="list-group mt-3">
                    <li className="list-group-item">Kando GmbH</li>
                    <li className="list-group-item">Am Bahnhof 3</li>
                    <li className="list-group-item">09599 Freiberg</li>
                    <li className="list-group-item">Deutschland</li>
                    <li className="list-group-item">Tel.: 03731 794455</li>
                    <li className="list-group-item">E-Mail: info.kando.freiberg@gmail.com</li>
                    <li className="list-group-item">Website: <a href="www.kando-freiberg.de">www.kando-freiberg.de</a>
                    </li>
                </ul>
            </div>

        </>
    )

}
export default Tabd3;