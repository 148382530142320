import React from 'react';
import {Redirect, Route} from "react-router-dom";


function DefaultRoute({children, ...rest}) {

    return (
        <Route
            {...rest}
            render={({location}) => ( children )
            }
        />
    );
}

export default DefaultRoute;