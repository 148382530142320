const Tabd4 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Cookies</strong></h2>
                Die Internetseiten der Kando GmbH verwenden Cookies. Cookies sind Textdateien, welche über einen
                Internetbrowser auf
                einem Computersystem abgelegt und gespeichert werden.
                Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte
                Cookie-ID. Eine
                Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche
                Internetseiten
                und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde.
                Dies
                ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person
                von anderen
                Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann
                über die
                eindeutige Cookie-ID wiedererkannt und identifiziert werden.
            </div>
            <div className="card text-start p-3 mt-4">
                Durch den Einsatz von Cookies kann die Kando GmbH den Nutzern dieser Internetseite nutzerfreundlichere
                Services
                bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.
                Mittels eines Cookies können die Informationen und Angebote auf unserer Internetseite im Sinne des
                Benutzers
                optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer unserer Internetseite
                wiederzuerkennen.
                Zweck dieser Wiedererkennung ist es, den Nutzern die Verwendung unserer Internetseite zu erleichtern.
                Der Benutzer
                einer Internetseite, die Cookies verwendet, muss beispielsweise nicht bei jedem Besuch der Internetseite
                erneut
                seine Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem Computersystem des
                Benutzers abgelegten
                Cookie übernommen wird. Ein weiteres Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der
                Online-Shop merkt
                sich die Artikel, die ein Kunde in den virtuellen Warenkorb gelegt hat, über ein Cookie.
                Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer
                entsprechenden
                Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft
                widersprechen.
                Ferner können bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere
                Softwareprogramme gelöscht
                werden. Dies ist in allen gängigen Internetbrowsern möglich. Deaktiviert die betroffene Person die
                Setzung von
                Cookies in dem genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen unserer
                Internetseite
                vollumfänglich nutzbar.
            </div>


        </>
    )

}
export default Tabd4;