const Tabd7 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Rechte der betroffenen Person</strong></h2>
            </div>
            <div className="card text-start p-3">
                <h2><strong>a) Recht auf Bestätigung</strong></h2>
                Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber eingeräumte Recht, von
                dem für die
                Verarbeitung Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene
                Daten
                verarbeitet werden. Möchte eine betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann sie
                sich hierzu
                jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
            </div>
            <div className="card text-start p-3 mt-4">
                <h2><strong>b) Recht auf Auskunft</strong></h2>
                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                Richtlinien- und
                Verordnungsgeber gewährte Recht, jederzeit von dem für die Verarbeitung Verantwortlichen unentgeltliche
                Auskunft
                über die zu seiner Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu
                erhalten. Ferner
                hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über folgende
                Informationen
                zugestanden:
                <ul className="list-group mt-3">
                    <li className="list-group-item">• die Verarbeitungszwecke</li>
                    <li className="list-group-item">• die Kategorien personenbezogener Daten, die verarbeitet werden
                    </li>
                    <li className="list-group-item">• die Empfänger oder Kategorien von Empfängern, gegenüber denen die
                        personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei
                        Empfängern in
                        Drittländern oder bei internationalen Organisationen
                    </li>
                    <li className="list-group-item">• falls möglich die geplante Dauer, für die die personenbezogenen
                        Daten gespeichert
                        werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer
                    </li>
                    <li className="list-group-item">• das Bestehen eines Rechts auf Berichtigung oder Löschung der sie
                        betreffenden
                        personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder
                        eines
                        Widerspruchsrechts gegen diese Verarbeitung
                    </li>
                    <li className="list-group-item">• das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde
                    </li>
                    <li className="list-group-item">• wenn die personenbezogenen Daten nicht bei der betroffenen Person
                        erhoben werden:
                        Alle verfügbaren Informationen über die Herkunft der Daten
                    </li>
                    <li className="list-group-item">• das Bestehen einer automatisierten Entscheidungsfindung
                        einschließlich Profiling
                        gemäß Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen Fällen — aussagekräftige
                        Informationen über
                        die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen
                        Verarbeitung
                        für die betroffene Person
                    </li>
                </ul>
                Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein
                Drittland oder
                an eine internationale Organisation übermittelt wurden. Sofern dies der Fall ist, so steht der
                betroffenen Person im
                Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu
                erhalten.
                Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit
                an einen
                Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.

            </div>

            <div className="card text-start p-3 mt-3">
                <h2><strong>c) Recht auf Bestätigung</strong></h2>
                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                Richtlinien- und
                Verordnungsgeber gewährte Recht, die unverzügliche Berichtigung sie betreffender unrichtiger
                personenbezogener Daten
                zu verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der
                Verarbeitung,
                die Vervollständigung unvollständiger personenbezogener Daten — auch mittels einer ergänzenden Erklärung
                — zu
                verlangen.
                Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu
                jederzeit an einen
                Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>d) Recht auf Löschung (Recht auf Vergessen werden)</strong></h2>
                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                Richtlinien- und
                Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu verlangen, dass die sie betreffenden
                personenbezogenen
                Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit die
                Verarbeitung nicht
                erforderlich ist:
                <ul className="list-group mt-3">
                    <li className="list-group-item">• Die personenbezogenen Daten wurden für solche Zwecke erhoben oder
                        auf sonstige
                        Weise verarbeitet, für welche sie nicht mehr notwendig sind.
                    </li>
                    <li className="list-group-item">• Die betroffene Person widerruft ihre Einwilligung, auf die sich
                        die Verarbeitung
                        gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO stützte, und es
                        fehlt an einer
                        anderweitigen Rechtsgrundlage für die Verarbeitung.
                    </li>
                    <li className="list-group-item">• Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch
                        gegen die
                        Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor,
                        oder die
                        betroffene Person legt gemäß Art. 21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.
                    </li>
                    <li className="list-group-item">• Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
                    <li className="list-group-item">• Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
                        rechtlichen
                        Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der
                        Verantwortliche
                        unterliegt.
                    </li>
                    <li className="list-group-item">• Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste
                        der
                        Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.
                    </li>
                </ul>
                Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung von
                personenbezogenen Daten,
                die bei der Kando GmbH gespeichert sind, veranlassen möchte, kann sie sich hierzu jederzeit an einen
                Mitarbeiter des
                für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der Kando GmbH wird veranlassen, dass dem
                Löschverlangen unverzüglich nachgekommen wird.
                Wurden die personenbezogenen Daten von der Kando GmbH öffentlich gemacht und ist unser Verein als
                Verantwortlicher
                gemäß Art. 17 Abs. 1 DS-GVO zur Löschung der personenbezogenen Daten verpflichtet, so trifft der Kando
                GmbH unter
                Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch
                technischer
                Art, um andere für die Datenverarbeitung Verantwortliche, welche die veröffentlichten personenbezogenen
                Daten
                verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene Person von diesen anderen für die
                Datenverarbeitung
                Verantwortlichen die Löschung sämtlicher Links zu diesen personenbezogenen Daten oder von Kopien oder
                Replikationen
                dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht erforderlich ist. Der
                Mitarbeiter der
                Kando GmbH wird im Einzelfall das Notwendige veranlassen.
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>e) Recht auf Einschränkung der Verarbeitung</strong></h2>
                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                Richtlinien- und
                Verordnungsgeber gewährte Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu
                verlangen, wenn eine
                der folgenden Voraussetzungen gegeben ist:
                <ul className="list-group mt-3">
                    <li className="list-group-item">
                        • Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und
                        zwar für eine
                        Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu
                        überprüfen.
                    </li>
                    <li className="list-group-item">
                        • Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der
                        personenbezogenen Daten ab
                        und verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen Daten
                    </li>
                    <li className="list-group-item">
                        • Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung nicht
                        länger, die
                        betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von
                        Rechtsansprüchen.
                    </li>
                    <li className="list-group-item">
                        • Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DS-GVO
                        eingelegt und es
                        steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der
                        betroffenen
                        Person überwiegen.
                    </li>
                </ul>
                Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person die Einschränkung
                von
                personenbezogenen Daten, die bei der Kando GmbH gespeichert sind, verlangen möchte, kann sie sich hierzu
                jederzeit
                an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der Kando GmbH
                wird die
                Einschränkung der Verarbeitung veranlassen
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>f) Recht auf Datenübertragbarkeit</strong></h2>
                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                Richtlinien- und
                Verordnungsgeber gewährte Recht, die sie betreffenden personenbezogenen Daten, welche durch die
                betroffene Person
                einem Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren
                Format zu
                erhalten. Sie hat außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch
                den
                Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die
                Verarbeitung auf
                der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf
                einem Vertrag
                gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren
                erfolgt,
                sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
                Interesse
                liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.
                Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Art. 20
                Abs. 1 DS-GVO
                das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen
                anderen
                Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die
                Rechte und
                Freiheiten anderer Personen beeinträchtigt werden.
                Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person jederzeit an
                einen
                Mitarbeiter der Kando GmbH wenden.
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>g) Recht auf Widerspruch</strong></h2>
                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                Richtlinien- und
                Verordnungsgeber gewährte Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit
                gegen die
                Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder
                f DS-GVO
                erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            </div>
            <div className="card text-start p-3 mt-1">
                Die Kando GmbH verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei
                denn, wir können
                zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und
                Freiheiten der
                betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
                Verteidigung von
                Rechtsansprüchen.
            </div>
            <div className="card text-start p-3 mt-1">
                Verarbeitet die Kando GmbH personenbezogene Daten, um Direktwerbung zu betreiben, so hat die betroffene
                Person das
                Recht, jederzeit Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger
                Werbung
                einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
                Widerspricht
                die betroffene Person gegenüber der Kando GmbH der Verarbeitung für Zwecke der Direktwerbung, so wird
                der Klubhaus
                e.V. die personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.
            </div>
            <div className="card text-start p-3 mt-1">
                Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben,
                gegen die
                sie betreffende Verarbeitung personenbezogener Daten, die bei der Kando GmbH zu wissenschaftlichen oder
                historischen
                Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch
                einzulegen, es sei
                denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe
                erforderlich.
                Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt jeden Mitarbeiter der
                Kando GmbH oder
                einen anderen Mitarbeiter wenden. Der betroffenen Person steht es ferner frei, im Zusammenhang mit der
                Nutzung von
                Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr Widerspruchsrecht
                mittels
                automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>h) Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</strong></h2>
                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                Richtlinien- und
                Verordnungsgeber gewährte Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung —
                einschließlich
                Profiling — beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung
                entfaltet oder sie
                in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung (1) nicht für den Abschluss oder
                die Erfüllung
                eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist, oder (2)
                aufgrund von
                Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig
                ist und diese
                Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten
                Interessen der
                betroffenen Person enthalten oder (3) mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.
            </div>
            <div className="card text-start p-3 mt-1">
                Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen
                Person und dem
                Verantwortlichen erforderlich oder (2) erfolgt sie mit ausdrücklicher Einwilligung der betroffenen
                Person, trifft
                die Kando GmbH angemessene Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten Interessen der
                betroffenen
                Person zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des
                Verantwortlichen,
                auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
            </div>
            <div className="card text-start p-3 mt-1">
                Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen geltend machen, kann sie
                sich hierzu
                jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
            </div>
            <div className="card text-start p-3 mt-3">
                <h2><strong>i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</strong></h2>
                Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                Richtlinien- und
                Verordnungsgeber gewährte Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu
                widerrufen.
                Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend machen, kann sie sich
                hierzu
                jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.

            </div>

        </>
    )

}
export default Tabd7;