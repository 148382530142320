const Tabd1 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Haftungsausschluss</strong></h2>
                Eine Haftung für die Richtigkeit, Vollständigkeit und Aktualität dieser Webseiten kann trotz
                sorgfältiger Prüfung durch den „Kando GmbH“ nicht übernommen werden. Der „Kando GmbH.“
                übernimmt insbesondere keinerlei Haftung für eventuelle Schäden oder Konsequenzen, die durch
                die direkte oder indirekte Nutzung der angebotenen Inhalte entstehen.
                Es wird ausdrücklich darauf hingewiesen, dass der „Kando GmbH.“ keine Verantwortung für den
                Inhalt von Seiten übernehmen, die mittels Link von den Web-Dokumenten des Klubhaus e.V.
                erreichbar sind.
                Für diese Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung dieser
                Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde.
            </div>
            <div className="card text-start mt-4 p-3">
                <h2><strong>Datenschutzerklärung</strong></h2>
                Wir freuen uns sehr über Ihr Interesse an unserer Firma. Datenschutz hat einen besonders hohen
                Stellenwert für den Vorstand der Kando GmbH. Eine Nutzung der Internetseiten des Kando GmbH. ist
                grundsätzlich ohne jede Angabe personenbezogener Daten möglich. Sofern eine betroffene Person
                besondere Services unseres Vereins über unsere Internetseite in Anspruch nehmen möchte, könnte
                jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung
                personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche
                Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.
                Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift,
                E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der
                Datenschutz-Grundverordnung und in Übereinstimmung mit den für den Kando GmbH. geltenden
                landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte unser
                Verein die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen, genutzten und
                verarbeiteten personenbezogenen Daten informieren. Ferner werden betroffene Personen mittels
                dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
                Die Kando GmbH hat als für die Verarbeitung Verantwortlicher zahlreiche technische und
                organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese
                Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können
                Internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein
                absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen
                Person frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch, an
                uns zu übermitteln.

            </div>

        </>
    )

}
export default Tabd1;