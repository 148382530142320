import '../../styles/Layout.css';
import Header from "./Header";



function LayoutComponent(props) {
    const {children,headerHidden} = props;
    return (
        <div className={"container"}>
            <header>
                <Header headerHidden={headerHidden}/>
            </header>
            <div className="container-body">
                {children}

            </div>

        </div>
    )
}

export default LayoutComponent;