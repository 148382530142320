const Tabd8 = () =>{
    return(
        <>
            <div className="card text-start p-3">
                <h2><strong>Datenschutz bei Bewerbungen und im Bewerbungsverfahren</strong></h2>
                Der für die Verarbeitung Verantwortliche erhebt und verarbeitet die personenbezogenen Daten von
                Bewerbern zum Zwecke
                der Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch auf elektronischem Wege erfolgen.
                Dies ist
                insbesondere dann der Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf dem elektronischen
                Wege,
                beispielsweise per E-Mail oder über ein auf der Internetseite befindliches Webformular, an den für die
                Verarbeitung
                Verantwortlichen übermittelt. Schließt der für die Verarbeitung Verantwortliche einen Anstellungsvertrag
                mit einem
                Bewerber, werden die übermittelten Daten zum Zwecke der Abwicklung des Beschäftigungsverhältnisses unter
                Beachtung
                der gesetzlichen Vorschriften gespeichert. Wird von dem für die Verarbeitung Verantwortlichen kein
                Anstellungsvertrag mit dem Bewerber geschlossen, so werden die Bewerbungsunterlagen zwei Monate nach
                Bekanntgabe der
                Absageentscheidung automatisch gelöscht, sofern einer Löschung keine sonstigen berechtigten Interessen
                des für die
                Verarbeitung Verantwortlichen entgegenstehen. Sonstiges berechtigtes Interesse in diesem Sinne ist
                beispielsweise
                eine Beweispflicht in einem Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).
            </div>


        </>
    )

}
export default Tabd8;